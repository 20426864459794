import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import { Col, Row } from 'styled-bootstrap-grid'
import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import GitHubSvg from '../assets/icons/github.svg'
import LinkedInSvg from '../assets/icons/linkedin.svg'
import TwitterSvg from '../assets/icons/twitter.svg'
import FullContainer from './FullContainer'
import StyledContainer from './StyledContainer'

export const ContactUs = (): JSX.Element => {
  const data = useStaticQuery(graphql`
    query AccreditedAgencyImageQuery {
      accreditedAgencyImage: file(
        relativePath: { eq: "accredited-agency.png" }
      ) {
        childImageSharp {
          fixed(width: 248, height: 118) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <StyledContainer>
      <ContactUsArticle id="contact">
        <FullContainer>
          <Row>
            <BusinessDetailsPanel col md={6}>
              <>
                <ContactTitle>Get In Touch</ContactTitle>
                <ContactContainer>
                  <ContactLinkContainer>
                    <ContactLink
                      href="mailto:hello@ravencode.co"
                      title="Email Raven Code"
                    >
                      hello@ravencode.co
                    </ContactLink>
                  </ContactLinkContainer>
                </ContactContainer>
              </>
              <>
                <ContactTitle>Follow Us</ContactTitle>
                <SocialLinks>
                  <SocialLinkItem>
                    <a
                      href="https://github.com/ravencodedev"
                      rel="noopener noreferrer"
                      title="Follow Raven Code on GitHub"
                    >
                      <GitHubIcon />
                    </a>
                  </SocialLinkItem>
                  <SocialLinkItem>
                    <a
                      href="https://twitter.com/ravencodedev"
                      rel="noopener noreferrer"
                      title="Follow Raven Code on Twitter"
                    >
                      <TwitterIcon />
                    </a>
                  </SocialLinkItem>
                  <SocialLinkItem>
                    <a
                      href="https://www.linkedin.com/company/ravencode"
                      rel="noopener noreferrer"
                      title="Follow Raven Code on LinkedIn"
                    >
                      <LinkedInIcon />
                    </a>
                  </SocialLinkItem>
                </SocialLinks>
              </>
              <>
                <ContactTitle>Partners</ContactTitle>
                <SocialLinks>
                  <SocialLinkItem>
                    <a
                      href="https://www.designrush.com/agency/software-development/uk/london"
                      title="Top Software Development Agencies in London - Accredited Agency - DesignRush.com"
                    >
                      <Img
                        title="DesignRush.com Accredited Agency"
                        alt="Code screen"
                        fixed={data.accreditedAgencyImage.childImageSharp.fixed}
                      />
                    </a>
                  </SocialLinkItem>
                </SocialLinks>
              </>
              <>
                <ContactTitle>Featured Articles</ContactTitle>
                <SocialLinks>
                  <SocialLinkItem>
                    <a
                      href="https://digitalfunnel.ie/how-to-increase-website-speed/"
                      title="How to Increase Website Speed"
                    >
                      How to Increase Website Speed - Digital Funnel
                    </a>
                  </SocialLinkItem>
                </SocialLinks>
              </>
            </BusinessDetailsPanel>
          </Row>
        </FullContainer>
      </ContactUsArticle>
    </StyledContainer>
  )
}

const ContactUsArticle = styled.article`
  padding: 4rem 0 0;
  position: relative;
  ${media.greaterThan('medium')`
    &:before {
      content: '';
      position: absolute;
      height: 100%;
      width: 50%;
    }
  `}
`

const column = css`
  flex: 0 0 100%;
  max-width: 100%;
  ${media.greaterThan('medium')`
    flex: 0 0 50%;
    max-width: 50%;
  `}
`
const BusinessDetailsPanel = styled(Col)`
  ${column}
  padding: 1rem;
  ${media.greaterThan('medium')`
    padding: 6rem 3rem 10vw;
  `}
`

const ContactTitle = styled.h6`
  font-size: 1.125rem;
  margin-bottom: 1.25rem;
  font-weight: 600;
  transition: all 0.1s cubic-bezier(0.72, 0.16, 0.345, 0.875);
  text-transform: uppercase;
`

const Address = styled.address`
  font-style: normal;
  margin-bottom: 1em;
`
const ContactContainer = styled.p`
  display: flex;
  flex-direction: column;
`

const ContactLinkContainer = styled.span``

const ContactLink = styled.a`
  color: ${({ theme }) => theme.colors.secondary};
`
const SocialLinks = styled.ul`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0 0 1.25rem;
  list-style: none;
  list-style-type: none;
`

const SocialLinkItem = styled.li``

const icon = css`
  height: 48px;
  width: 48px;
  fill: #fff;
`

const GitHubIcon = styled(GitHubSvg)`
  ${icon}
`
const TwitterIcon = styled(TwitterSvg)`
  ${icon}
`
const LinkedInIcon = styled(LinkedInSvg)`
  ${icon}
`
